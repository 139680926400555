import styled from "styled-components";
import Logo from "./Logo";

// Style for the header container
const HeaderContainer = styled.div`
  border-bottom: 1px solid var(--color-grey-200);

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    max-width: 130rem;
    padding: 0 5rem;
    margin: 0 auto;
  }
`;

// Style for the select dropdown
const StyledSelect = styled.select`
  padding: 0.6rem 1.2rem;
  border: 1px solid var(--color-grey-300);
  border-radius: 0.4rem;
  background-color: var(--color-brand-500);
  color: var(--color-grey-0);
  font-size: 1.4rem;
  appearance: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--color-brand-600);
  }

  &:focus {
    outline: none;
    border-color: var(--color-brand-500);
    box-shadow: 0 0 0 3px rgba(100, 149, 237, 0.2); /* subtle blue highlight */
  }
`;

// Style for the option elements
const StyledOption = styled.option`
  color: var(--color-grey-0);
  font-size: 1.4rem;
  padding: 1rem;
`;

function Header({ isPersian, setIsPersian }) {
  return (
    <HeaderContainer>
      <div>
        <a href="https://mondfx.com/">
          <Logo />
        </a>

        <StyledSelect
          defaultValue="en"
          value={isPersian}
          onChange={(e) => setIsPersian(e.target.value)}
        >
          <StyledOption value="en">English</StyledOption>
          <StyledOption value="fr">فارسی</StyledOption>
        </StyledSelect>
      </div>
    </HeaderContainer>
  );
}

export default Header;
