import { AJAX } from "../utils/helpers";
import { MAIN_URL } from "../utils/constants";

export async function getUrl(email) {
  try {
    const data = await AJAX(`${MAIN_URL}/campaign/store`, "POST", {
      campaign_id: 1,
      email: email,
    });
    return data;
  } catch (e) {
    console.log(e);
  }
}
