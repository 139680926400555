import styled, { keyframes } from "styled-components";
import Header from "./Header";
import AppTopLinks from "./AppTopLinks";
import Heading from "./Heading";
import Footer from "./Footer";
import AnimatedButton from "./AnimatedButton";
import SpinnerMini from "./SpinnerMini";
import { useState } from "react";
import { getUrl } from "../services/apiFunctions";

// Keyframe animations for a smooth fade-in effect
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Keyframe for gradient animation on highlight span
const gradientMove = keyframes`
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
`;

// Input field animation and minimal style
const Input = styled.input`
  border: none;
  border-bottom: 2px solid var(--color-grey-300);
  background-color: transparent;
  width: 28rem;
  padding: 1rem;
  font-size: 1.4rem;
  outline: none;
  transition: border-color 0.3s ease;
  animation: ${fadeIn} 0.8s ease;
  border-radius: 10px;
  &:focus {
    border-bottom: 2px solid var(--color-brand-700);
  }
`;

// Container with animation for smooth entrance
const LayoutContainer = styled.div`
  max-width: 100rem;
  padding: 4rem 2rem;
  margin: 0 auto;
  color: var(--color-grey-800);
  background-color: var(--color-grey-0);
  animation: ${fadeIn} 1s ease-out both;

  div {
    line-height: 1.7;
    margin-bottom: 5rem;
    animation: ${fadeIn} 1.2s ease-out;

    & .main-paragraph {
      margin-bottom: 3rem;
      font-size: 1.8rem;
      color: var(--color-grey-700);
    }

    & ul {
      padding: 2rem;
      list-style: unset;
      margin: 2rem 0;

      & li {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        color: var(--color-grey-700);
        position: relative;
        padding-left: 2rem;
        animation: ${fadeIn} 1.3s ease-out both;

        &::marker {
          color: var(--color-brand-500);
        }

        /* &::before {
          content: "•";
          position: absolute;
          left: 0;
          top: 0.2rem;
          color: var(--color-brand-500);
        } */
      }
    }

    & aside {
      background-color: var(--color-grey-100);
      padding: 1.5rem;
      border-radius: 0.8rem;
      margin-top: 3rem;
      margin-bottom: 3rem;
      font-size: 1.4rem;
      color: var(--color-grey-600);
      border-left: 4px solid var(--color-brand-500);
      transition: transform 0.3s ease;
      animation: ${fadeIn} 1.5s ease-out both;

      &:hover {
        transform: scale(1.02);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      }
    }

    & form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      margin-top: 4rem;
      animation: ${fadeIn} 1.6s ease-out both;
    }
  }
`;

// Cool gradient effect with animation for highlight span
const Span = styled.span`
  background-image: linear-gradient(
    120deg,
    var(--color-brand-500),
    var(--color-brand-700),
    var(--color-brand-400)
  );
  background-size: 200%;
  background-clip: text;
  color: transparent;
  animation: ${gradientMove} 1s infinite alternate;
`;

function AppLayout() {
  const [email, setEmail] = useState("");
  const [isPersian, setIsPersian] = useState("en");
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      getUrl(email).then((res) => {
        console.log(res);
        if (res?.success) setUrl(res.url);
        setIsLoading(false);
      });
    } catch (e) {
    } finally {
    }
  }

  return (
    <>
      <AppTopLinks />
      <div
        style={{
          fontFamily: isPersian === "en" ? "iransans" : "inherit",
        }}
      >
        <Header
          className="main-header"
          isPersian={isPersian}
          setIsPersian={setIsPersian}
        />
        <LayoutContainer dir={isPersian === "en" ? "ltr" : "rtl"}>
          <Heading
            as="h4"
            style={{
              marginBottom: "3rem",
              fontSize: "6rem",
            }}
          >
            {isPersian === "en" ? (
              <Span className="big-header">Congratulations!</Span>
            ) : (
              <Span className="big-header">تبریک!</Span>
            )}
          </Heading>
          <div>
            {isPersian === "en" ? (
              <>
                {" "}
                <p className="main-paragraph">
                  You are one of the lucky winners of the $100 prize in the 1001
                  Mond FX campaign. This campaign has been designed to
                  familiarize traders with the broker's services and compare
                  them with other brokers. As part of this campaign, you will
                  receive a $100 gift from Mond FX directly into your account.
                  This $100, unlike a bonus, can not only be used for trading
                  but is also eligible for withdrawal.
                </p>
                <Heading as="h3">Guide to Claiming the Prize:</Heading>
                <ul>
                  <li>
                    After entering your email, click the 'Submit' button below.
                    Then click the 'Start' button to proceed to the broker's
                    registration page.
                  </li>
                  <li>On the new page, fill in the required information.</li>
                  <li>
                    In the account type section, be sure to select the "1001
                    Account" and create your account.
                  </li>
                  <li>
                    To receive the $100 bonus, go to the Bonuses section and
                    select the $100 bonus.
                  </li>
                  <li>Enter your account into MetaTrader and start trading.</li>
                </ul>
                <aside>
                  <strong>Note:</strong> This gift is valid until the end of
                  October.
                </aside>
                <Heading as="h3">
                  <span>
                    To receive the terms and conditions and start the prize
                    redemption process, enter your email below:
                  </span>
                </Heading>
                {!url ? (
                  <form onSubmit={handleSubmit}>
                    <Input
                      placeholder="Please enter your email..."
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <AnimatedButton>
                      {isLoading ? <SpinnerMini /> : "Submit"}
                    </AnimatedButton>
                  </form>
                ) : (
                  <div
                    className="flex-center"
                    style={{
                      marginTop: "3rem",
                    }}
                  >
                    <a href={url}>
                      <AnimatedButton>Start Campaign</AnimatedButton>
                    </a>
                  </div>
                )}
              </>
            ) : (
              <>
                {" "}
                <p className="main-paragraph">
                  شما یکی از برندگان خوش شانس جایزه صد دلاری کمپین 1001 موند اف
                  ایکس هستید. این کمپین در جهت آشنایی بیشتر معامله گران با خدمات
                  بروکر و مقایسه با سایر بروکرها ایجاد شده است. در این کمپین،
                  شما 100 دلار هدیه از طرف بروکر موند اف ایکس، داخل حساب کاربری
                  خود دریافت خواهید کرد. این 100 دلار، بر خلاف بونوس علاوه بر
                  قابلیت استفاده برای ترید، قابلیت برداشت را نیز دارد.
                </p>
                <Heading as="h3">راهنمای دریافت جایزه:</Heading>
                <ul>
                  <li>
                    پس از ورود ایمیل خود، روی دکمه «ارسال» در پایین کلیک کنید.
                    سپس روی دکمه «شروع» کلیک کنید تا به صفحه ثبت‌نام کارگزاری
                    منتقل شوید.
                  </li>
                  <li>
                    در صفحه باز شده، اطلاعات تکمیلی و مورد نیاز را وارد کنید.
                  </li>
                  <li>
                    در بخش نوع اکانت، حتما اکانت 1001 را انتخاب کرده و حساب خود
                    را ایجاد کنید.
                  </li>
                  <li>
                    برای دریافت بونوس ۱۰۰ دلاری، وارد بخش بونوس‌ها شوید و بونوس
                    ۱۰۰ دلاری را انتخاب کنید.
                  </li>
                  <li>
                    با وارد کردن اکانت در متاتریدر معاملات خود را شروع کنید.
                  </li>
                </ul>
                <aside>
                  <strong>توجه:</strong> این هدیه تا پایان ماه اکتبر اعتبار
                  دارد.
                </aside>
                <Heading as="h3">
                  <span>
                    برای دریافت شرایط و قوانین، و شروع عملیات کسب هدیه ایمیل خود
                    را در کادر زیر وارد کنید.
                  </span>
                </Heading>
                {!url ? (
                  <form onSubmit={handleSubmit}>
                    <Input
                      placeholder="لطفا ایمیل خود را وارد کنید..."
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <AnimatedButton>
                      {isLoading ? <SpinnerMini /> : "تایید"}
                    </AnimatedButton>
                  </form>
                ) : (
                  <div
                    className="flex-center"
                    style={{
                      marginTop: "3rem",
                    }}
                  >
                    <a href={url}>
                      <AnimatedButton>شروع کمپین</AnimatedButton>
                    </a>
                  </div>
                )}
              </>
            )}
          </div>
        </LayoutContainer>
      </div>
      <Footer />
    </>
  );
}

export default AppLayout;
