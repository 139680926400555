import { TIMEOUT_SEC } from "./constants";

const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${s} second`));
    }, s * 1000);
  });
};

export const AJAX = async function (
  url,
  method = "GET",
  uploadData = undefined
) {
  try {
    console.log(url);
    const fetchOptions = {
      method, // This takes the method passed as an argument
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (uploadData) fetchOptions.body = JSON.stringify(uploadData); // Only include body if there is data to upload

    const fetchPro = fetch(url, fetchOptions);

    // Race the fetch call against a timeout
    const res = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);
    const data = await res.json();

    // Check if the request was successful
    if (!res.ok) throw new Error(`${data.message} (${res.status})`);

    return data;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};
